import * as React from "react";

export const useInterval = (callback: () => void, seconds: number) => {
    const intervalRef = React.useRef<number | undefined>();
    React.useEffect(() => {
        intervalRef.current = window.setInterval(() => {
            callback();
        }, seconds * 1000);
        return () => {
            if(intervalRef.current != undefined) {
                window.clearInterval(intervalRef.current);
            }
        }
    });
}