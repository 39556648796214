import * as React from "react";
import vertexShader from "../../../../shaders/vertex/water-sine-sum-circular.glsl";
import fragmentShader from "../../../../shaders/fragment/water.glsl";
import { Canvas, extend, GroupProps, ReactThreeFiber, ShaderMaterialProps, useFrame, useThree } from "@react-three/fiber";
import { Color, DoubleSide, MathUtils, ShaderMaterial, ShaderMaterialParameters, ShaderLib, UniformsUtils, Vector3, UniformsLib, FrontSide } from "three";

const builtinShader = ShaderLib.physical;

let uniforms = UniformsUtils.merge([builtinShader.uniforms, UniformsLib.lights, UniformsLib.common]);
uniforms.time = { value: 0 };
uniforms.lookatVector =  { value: new Vector3(0,0,0) };
uniforms.amplifier = { value: 0.1 };
uniforms.speed = { value: 0.5 };
uniforms.color = { value: new Color("#080fde") };
uniforms.opacity = { value: 0.5 };
uniforms.metalness = { value: 0 };
uniforms.roughness = { value: 10 };
uniforms.diffuse = { value: new Color("#080fde") };
uniforms.emissive = { value: new Color("#080fde")};
uniforms.specularColor = { value: new Color("#ffffff") };
uniforms.specularIntensity = { value: 1.0};
uniforms.waterAmplitude = { value: 0.02 };
uniforms.waterWaveLength = { value:  0.1};
uniforms.waterSpeedAmplifier = { value: uniforms.waterWaveLength.value / 12};
uniforms.waterWaveCutoff = { value: 2.1 };

export type Props = {
    colour: Color
} & GroupProps;

export const WaterTile : React.FC<Props> = (props) => {

    const { colour } = props;
    uniforms.diffuse = { value: colour };
    uniforms.emissive = { value: colour };
    uniforms.color = { value: colour };

    const { camera } = useThree();

    const materialRef = React.useRef<ShaderMaterialParameters & typeof uniforms>();

    React.useEffect(() => {
        if(camera == null) return;
        camera.position.set(-500,500,0);
    }, [camera]);

    useFrame((state) => {
        if(materialRef?.current?.uniforms == null) return;
        const vec = new Vector3();
        state.camera.getWorldDirection(vec);
        materialRef.current.uniforms.lookatVector.value = vec;
        materialRef.current.uniforms.time.value = state.clock.elapsedTime;
    });

    return (
        <>
            <group {...props} rotation={[MathUtils.degToRad(270),0,0]}>
                <mesh>
                    <boxGeometry args={[1, 1, 1, 12, 12, 1]} />
                    <shaderMaterial
                        vertexShader={vertexShader}
                        attach="material"
                        fragmentShader={builtinShader.fragmentShader}
                        uniforms={uniforms}
                        opacity={0.1}
                        transparent={true}
                        lights={true}
                        ref={materialRef} 
                    />
                </mesh>
            </group>
            <group {...props}  rotation={[MathUtils.degToRad(270),MathUtils.degToRad(0),MathUtils.degToRad(270)]}>
                <mesh>
                    <boxGeometry args={[1, 1, 1, 12, 12, 1]} />
                    <shaderMaterial
                        vertexShader={vertexShader}
                        attach="material"
                        fragmentShader={builtinShader.fragmentShader}
                        uniforms={uniforms}
                        opacity={0.1}
                        transparent={true}
                        lights={true}
                        ref={materialRef} 
                    />
                </mesh>
            </group>
        </>
    )
}

export default WaterTile;