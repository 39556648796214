import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GroupProps, useFrame, useThree } from "@react-three/fiber";
import { Color, ShaderLib, ShaderMaterialParameters, UniformsLib, UniformsUtils, Vector3 } from "three";
import { Loader } from "../../common/Loader";

type Props = {

} & GroupProps;

export const Piggy : React.FC<Props> = (props) => {
    return (
        <React.Suspense fallback={<Loader shape="sphere" size={1} />}>
            <Model {...props} />
        </React.Suspense>
    )
}

const Model = ({ ...props }) => {
  const group = useRef();
  const builtinShader = ShaderLib.normal;
  let uniforms = UniformsUtils.merge([builtinShader.uniforms, UniformsLib.lights, UniformsLib.common]);
  uniforms.time = { value: 0 };
  uniforms.lookatVector =  { value: new Vector3(0,0,0) };
  uniforms.amplifier = { value: 10.0 };
  uniforms.speed = { value: 2.0 };
  uniforms.color = { value: new Color("#ffffff") };

  const { camera, scene } = useThree();

  const materialRef = React.useRef<ShaderMaterialParameters & typeof uniforms>();

  React.useEffect(() => {
      if(camera == null) return;
      camera.position.set(-500,500,0);
  }, [camera]);

  useFrame((state) => {
      if(materialRef?.current?.uniforms == null) return;
      const vec = new Vector3();
      state.camera.lookAt(new Vector3(0,0,0));
      state.camera.getWorldDirection(vec);
      materialRef.current.uniforms.lookatVector.value = vec;
      materialRef.current.uniforms.time.value = state.clock.elapsedTime;
  });

  //@ts-ignore
  const { nodes, materials } = useGLTF("/models/town/chars/piggy.gltf");
  return (
    //@ts-ignore
    <group {...props} dispose={null}>
    <mesh
      castShadow
      receiveShadow
      geometry={nodes.piggy_standing.geometry}
      material={materials.mat0_standing_piggy_full}
      rotation={[Math.PI / 2, 0, 0]}
    />
  </group>
  );
}

useGLTF.preload("/models/town/chars/moomoo.gltf");