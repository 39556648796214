import * as React from "react";
import { Router } from "@reach/router"
import { useContextBridge, OrbitControls, Stars, PerspectiveCamera, Environment, ContactShadows, Float, Sparkles } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { EffectComposer, DepthOfField, Bloom } from "@react-three/postprocessing";
import { ReactReduxContext } from "react-redux";
import { PlanetDescription } from "../../components/galaxy/planetdescription";
import SEO from "../../components/layout/seo";
import { useWindowSize } from "../../hooks/useWindowSize";
import { GrassTile } from "../../components/three/town/tiles/grassTile";
import { Ground } from "../../components/three/town/ground";
import { BackSide, Color, DoubleSide, Euler, MathUtils } from "three";
import { LayerMaterial, Color as Base, Depth, Noise } from 'lamina';
import { MooMoo } from "../../components/three/town/chars/moomoo";
import { MushroomHouse } from "../../components/three/town/buildings/mushroomHouse";
import { RandomCharacter } from "../../components/three/town/chars/random";
import { useTownTimeOfDay } from "../../hooks/town/useTownTimeOfDay";
import { Clock } from "../../components/town/Clock";
import { Helmet } from "react-helmet";
import { useInterval } from "../../hooks/useInterval";
import { useWebGLCheck } from "../../hooks/useWebGLCheck";

const TownPage : React.FC = () => {
    const ContextBridge = useContextBridge(ReactReduxContext);
    const {width, height} = useWindowSize();
    
    //TimeOfDay
    //Check on client if timeOfDay changes while viewing the page.
    const [timeOfDay, setTimeOfDay] = React.useState(useTownTimeOfDay());
    useInterval(() => {
        const timeOfDayCheck = useTownTimeOfDay();
        if(timeOfDay.name != timeOfDayCheck.name) {
            setTimeOfDay(timeOfDayCheck);
        }
    }, 1);


    //Audio Button
    const audioRef = React.useRef<HTMLAudioElement>();
    const [playAudio, setPlayAudio] = React.useState(false);

    //WebGL check
    const { modal, isSupported } = useWebGLCheck();

    if(!isSupported) {
        return <>{modal}</>;
    }

    return (
        <div style={{width: width, height: height, backgroundColor: timeOfDay.bgColour, fontFamily: "Nunito"}}>

            <SEO    
                title="Town Tales"
                image="/images/seo/towntales.png"
                description="Cozy times with a cast of character."
            />

            <Helmet>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Nunito" />
            </Helmet>

            <header style={{position: "absolute", top: "2%", left: "2%", backgroundColor: "black", padding: "2rem", borderRadius: "4rem", zIndex: 100}}>
                <Clock />
                <button 
                    type="button"
                    style={{fontSize: 24, color: "white",  background: "none", border: "none", pointerEvents: "all"}}
                    onClick={() => {
                        if(playAudio){
                            audioRef.current?.pause();
                            setPlayAudio(false);
                        } else {
                            audioRef.current?.play();
                            setPlayAudio(true);
                        }
                    }}
                >
                    {playAudio ? "Pause" : "Play"}
                </button>
            </header>   

            <audio loop ref={audioRef as any}>
                <source src="/audio/town/genechillin.ogg" type="audio/ogg" />
                <source src="/audio/town/genechillin.mp3" type="audio/mpeg" />
            </audio> 

            <Canvas>
                <ContextBridge>
                    <OrbitControls 
                        minDistance={6} maxDistance={10} 
                        maxPolarAngle={Math.PI / 2.1} 
                        target={[0,0,0]} 
                        enablePan={false}
                    />
                    <PerspectiveCamera makeDefault far={5000} near={2} />


                    <hemisphereLight 
                        args={[new Color(timeOfDay.skyColour), new Color(timeOfDay.groundColour)]}
                        intensity={2.0}
                    />

                    {timeOfDay.showHomeLight &&
                    <>
                        <pointLight position={[0,1,0]} />
                    </>
                    }
                 
                    <pointLight position={[0, 10, 0]} />

                    <Float>

                        <RandomCharacter position-x={-1} />

                        <MushroomHouse position={[0,0.5,0]} rotation={[0,-Math.PI / 2,0]} />

                        <Ground waterColour={timeOfDay.waterColour} />  
                    </Float>

                    {timeOfDay.showStars && <Stars count={timeOfDay.starCount} /> }

                    <Sparkles count={timeOfDay.sparkleCount} size={2} position={[0,0,0]} color={timeOfDay.sparkleColour} scale={12} />

                    <EffectComposer>
                        <Bloom intensity={timeOfDay.bloomIntensity} luminanceThreshold={0} luminanceSmoothing={0.5} height={100} />
                    </EffectComposer>     
                </ContextBridge>

            </Canvas>      
        </div>
    );
}

export default TownPage;