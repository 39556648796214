import * as React from "react";
import { Color, MathUtils } from "three";
import { CobbleStoneTile } from "./tiles/cobbleStone";
import { DirtTile } from "./tiles/dirtTile";
import { GrassTile } from "./tiles/grassTile";
import WaterTile from "./tiles/waterTile";

type Props = {
    waterColour: string
}
export const Ground : React.FC<Props> = ({waterColour}) => {

    const waterColourInfo = new Color(waterColour);

    return (
        <>
            <CobbleStoneTile position={[0,0,0]} />
            <GrassTile position={[1,0,0]} />
            <GrassTile position={[1,0.15,0]} />
            <DirtTile position={[-1,0,0]} />
            <GrassTile position={[-2,0,0]} />
            <GrassTile position={[-2,0.15,0]} />
            <GrassTile position={[-3,0,0]} />
            
            <GrassTile position={[0,0,1]} />
                <GrassTile position={[0,0.15,1]} />
            <GrassTile position={[1,0,1]} />
                <GrassTile position={[1,0.15,1]} />
            <GrassTile position={[-1,0,1]} />
                <GrassTile position={[-1,0.15,1]} />
            <GrassTile position={[-2,0,1]} />
                <GrassTile position={[-2,0.15,1]} />
            <GrassTile position={[-3,0,1]} />
                <GrassTile position={[-3,0.15,1]} />

            <DirtTile position={[0,0,-1]} />
            <GrassTile position={[1,0,-1]} />
            <DirtTile position={[-1,0,-1]} />
            <GrassTile position={[-2,0,-1]} />
            <GrassTile position={[-3,0,-1]} />

            <GrassTile position={[0,0,-2]} />
            <GrassTile position={[1,0,-2]} />
            <DirtTile position={[-1,0,-2]} />
            <DirtTile position={[-2,0,-2]} />
            <DirtTile position={[-3,0,-2]} />


            <GrassTile position={[2,0,-1]} />
            <WaterTile colour={waterColourInfo} position={[2,0,-2]} />
            <WaterTile colour={waterColourInfo} position={[3,0,-1]} />
            <WaterTile colour={waterColourInfo} position={[3,0,-2]} />
            <GrassTile position={[0,0,2]} />
                <GrassTile position={[0,0.15,2]} />
            <GrassTile position={[1,0,2]} />
            <GrassTile colour={waterColourInfo} position={[-1,0,2]} />
            <GrassTile colour={waterColourInfo} position={[-2,0,2]} />
            <WaterTile colour={waterColourInfo} position={[-3,0,2]} />
            <WaterTile colour={waterColourInfo} position={[-3,0,3]} />
            <WaterTile colour={waterColourInfo} position={[-2,0,3]} />
            <GrassTile colour={waterColourInfo} position={[-1,0,3]} />
            <WaterTile colour={waterColourInfo} position={[0,0,3]} />
            <WaterTile colour={waterColourInfo} position={[1,0,3]} />
            <WaterTile colour={waterColourInfo} position={[2,0,3]} />
            <WaterTile colour={waterColourInfo} position={[3,0,3]} />

            <WaterTile colour={waterColourInfo} position={[-3,0,4]} />
            <WaterTile colour={waterColourInfo} position={[-2,0,4]} />
            <WaterTile colour={waterColourInfo} position={[-1,0,4]} />
            <WaterTile colour={waterColourInfo} position={[0,0,4]} />
            <WaterTile colour={waterColourInfo} position={[1,0,4]} />
            <WaterTile colour={waterColourInfo} position={[2,0,4]} />
            <WaterTile colour={waterColourInfo} position={[3,0,4]} />

            <WaterTile colour={waterColourInfo} position={[4,0,-2]} />
            <WaterTile colour={waterColourInfo} position={[4,0,-1]} />
            <WaterTile colour={waterColourInfo} position={[4,0,0]} />
            <WaterTile colour={waterColourInfo} position={[4,0,1]} />
            <WaterTile colour={waterColourInfo} position={[4,0,2]} />
            <WaterTile colour={waterColourInfo} position={[4,0,3]} />
            <WaterTile colour={waterColourInfo} position={[4,0,4]} />
            
            <GrassTile position={[2,0,2]} />
            <WaterTile colour={waterColourInfo} position={[3,0,2]} />
            <GrassTile position={[2,0,0]} />
            <WaterTile colour={waterColourInfo} position={[3,0,0]} />
            <WaterTile colour={waterColourInfo} position={[2,0,1]} />
            <WaterTile colour={waterColourInfo} position={[3,0,1]} />

        </>
    );
}