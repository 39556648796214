/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
//@ts-nocheck

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GroupProps } from "@react-three/fiber";
import { Loader } from "../../common/Loader";

type Props = {

} & GroupProps;

export const DirtTile = (props) => {
    return (
        <React.Suspense fallback={<Loader shape="box" size={1} />}>
            <Model {...props} />
        </React.Suspense>
    )
}

const Model = ({ ...props }) => {
  const group = useRef();
  const { nodes, materials } = useGLTF("/models/town/tiles/dirt.gltf");
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Dirt.geometry}
        material={materials.mat0_Box}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </group>
  );
}

useGLTF.preload("/models/town/tiles/dirt.gltf");
