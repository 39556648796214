import getHours from 'date-fns/getHours'

const timesOfDay = {
    day: {
        name: "day",
        bgColour: "#55aadd",
        skyColour: "#eaec4a",
        groundColour: "#ffffff",
        bloomIntensity: 1.0,
        showStars: false,
        starCount: 0,
        showHomeLight: false,
        waterColour: "#038dc1",
        sparkleColour: "#10db50",
        sparkleCount: 250
    },
    sunrise: {
        name: "sunrise",
        bgColour: "#ffb778",
        skyColour: "#ffb778",
        groundColour: "#f7bb77",
        bloomIntensity: 0.65,
        showStars: true,
        starCount: 100,
        showHomeLight: true,
        waterColour: "#ffb778",
        sparkleColour: "#72c4bd",
        sparkleCount: 250
    },
    sunset: {
        name: "sunset",
        bgColour: "#9b3d00",
        skyColour: "#de7008",
        groundColour: "#555555",
        bloomIntensity: 0.65,
        showStars: true,
        starCount: 100,
        showHomeLight: true,
        waterColour: "#b3eba0",
        sparkleColour: "#ffffff",
        sparkleCount: 250
    },
    night: {
        name: "night",
        bgColour: "#1a0728",
        skyColour: "#1a0728",
        groundColour: "#000000",
        bloomIntensity: 0.5,
        showStars: true,
        starCount: 500,
        showHomeLight: true,
        waterColour: "#1a1f44",
        sparkleColour: "#db9c10",
        sparkleCount: 250
    }
};

export const useTownTimeOfDay = (hour?: number) => {
    if(hour == null) {
        const now = Date.now();
        hour = getHours(now);
    }
    
    if(hour >= 9 && hour <= 17) return timesOfDay.day;
    if(hour >= 18 && hour <= 19) return timesOfDay.sunset;
    if((hour >= 20 && hour <= 24) || hour >= 0 && hour <= 5) return timesOfDay.night;
    if(hour >= 6 && hour <= 8) return timesOfDay.sunrise;
    return timesOfDay.day;
}