import { GroupProps } from "@react-three/fiber";
import * as React from "react";
import { Bun } from "./bun";
import { Maggie } from "./maggie";
import { MooMoo } from "./moomoo";
import { Piggy } from "./piggy";
import { Raccoon } from "./raccoon";

type Props = {} & GroupProps;

export const RandomCharacter : React.FC<Props> = (props) => {
    const Character = React.useMemo(() => {
        const random = Math.random();

        //todo: maybe use lazy loading for components 
        const chars = [
            <Bun scale={0.5} position-y={0.1}  rotation={[0,-Math.PI / 2, 0]}  {...props} />,
            <Maggie scale={0.25} position-y={0.22} {...props}  />,
            <MooMoo scale={0.25} rotation={[0,-Math.PI / 2, 0]} position-y={0.05} {...props}  />,
            <Piggy scale={0.35} rotation={[0,-Math.PI / 2, 0]}  position-y={0.1} {...props}  />,
            <Raccoon scale={0.25} position-y={0.1}  {...props}  />
        ];

        const max = chars.length;
        const index = Math.floor(random * max);
        return chars[index];
    }, []);
    return (
        <>
            {Character}
        </>
    );
};