import { format, getHours, getMinutes } from "date-fns";
import * as React from "react";
import { useInterval } from "../../hooks/useInterval";

type Props = {

};
export const Clock : React.FC<Props> = () => {
    const [now, setNow] = React.useState(Date.now());
    const [showSeparator, setShowSeperator] = React.useState(true);

    useInterval(() => {
        setNow(Date.now())
        setShowSeperator(!showSeparator);
    }, 1);

    return (
        <section style={{color: "white", fontSize: "2.5rem"}}>
            <span>{format(now, "HH")}</span>
            <span style={{visibility: showSeparator ? "initial" : "hidden" }}>:</span>
            <span>{format(now, "mm")}</span>
        </section>
    );  
}